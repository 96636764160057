import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Seo from '../components/seo'

import SuccessSeoImage from '../assets/images/bkgd-main.jpg'

class Success extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query SuccessMeta {
                    site {
                        siteMetadata {
                            title
                            description
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout page='about'>
                    <Seo title={`Success | ${data.site.siteMetadata.title}`} description={`${data.site.siteMetadata.description}`} image={SuccessSeoImage} />
                    <Banner title={`Success!`} pageStyle={`login`} />
                    <section id='content'>
                        <Container>
                            <Row>
                                <Col xs='12' sm='12' md='12' lg='12'>
                                    <h2>Thank you for contacting us.</h2>
                                    <p>We've received your email and will respond as soon as possible.</p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default Success